<template>
  <div class="containerBox">
    <div v-for="(item, index) in dataList" :key="index" class="item">
      <div class="left">
        <div class="job" @click="goDetail(item)">{{ item.name | textFormat}}</div>
        <div class="des">
          <span class="money" v-if="item.salary_lowest">{{ item.salary_lowest | textFormat}}K — {{ item.salary_highest | textFormat}}K</span>
          <span class="money" v-else>薪资面议</span>
          <span class="adress">{{ item.city_name_en | priorFormat(item.city_name_zh, LOCALE) }}</span>
          <span class="margin_l_r8">|</span>
          <span>{{ item.level_require | levelRequireFormat("zh") }}</span>
          <span class="date">发布于{{ item.created_time | secondFormat}}</span>
        </div>
      </div>
      <div class="show_type1">
        <div class="content">
          <div  v-if="showType === 1" class="img_box">
            <img :src="item.logo" alt="" class="img" v-if="item.logo">
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/default-logo.jpg" alt="默认图"
                 class="img" v-else>
          </div>
          <div  v-if="showType === 1">
            <div class="company" >{{ item.name_en | priorFormat(item.name_zh, LOCALE) }}</div>
            <div class="company_des">
              <span>{{ item.industry | industryFormat('zh')}}</span>
              <span class="margin_l_r8">|</span>
              <span>{{ item.scale | staffSizeFormat("zh")}}</span>
            </div>
          </div>
        </div>
        <div  class="right">
          <div style="margin-right:20px">
             <immediatelyTalk v-if="MEETING_STATUS==2 && item.person_in_charge && item.is_booth_person == 1" :channel_id="item.live_id" :oToId="item.one2one_id" :userId="item.person_in_charge" class="margin_t_4">
               <el-button type="primary" size="mini" round slot="btn">立即面试</el-button>
             </immediatelyTalk>
             <span v-else style="opacity: 0;">wanggou11</span>
          </div>
          <div>
               <submitResume
                :position_id="item.id"
                @successChange="successSubmitResume">
              </submitResume>
          <orderTalk
              class="margin_t_4"
              :in_companyId="item.company_id"
              :in_userId="item.person_in_charge"
              :position_id="item.id"
              @successChange="successChange"
          ></orderTalk>
          </div>
        </div>
      </div>
      <!-- <div v-else class="show_type2">
        <submitResume
            :position_id="item.id"
            class="margin_r_12"
            @successChange="successSubmitResume"></submitResume>
        <orderTalk
            v-if="MEETING_STATUS != 2"
            :in_companyId="item.company_id"
            :in_userId="item.person_in_charge"
            :position_id="item.id"
            @successChange="successChange"
        ></orderTalk>
        <immediatelyTalk v-else :channel_id="item.live_id"></immediatelyTalk>
      </div> -->
    </div>
    <noDataImg v-if="dataList.length === 0"></noDataImg>
  </div>
</template>

<script>
  import submitResume from "~qlc/components/button/submitResume";
  import orderTalk from "~qlc/components/button/orderTalk";
  import immediatelyTalk from "~scr/components/button/immediatelyTalk";
  export default {
    name: "positionList",
    props: {
      // 数据列表
      dataList: {
        type: Array,
        default() {
          return []
        }
      },
      // 展现方式
      // 1是在职位中，鼠标移动上去，显示投递简历，预约面试，  2 是在xxx中，直接显示投递简历，预约面试
      showType: {
        type: Number | String,
        default: 1
      }
    },
    components: { 
      submitResume, 
      orderTalk,
       immediatelyTalk },
    data() {
      return {}
    },
    methods: {
      goDetail(item) {
        this.$router.push({
          path: "/positionDetail",
          query: {
            parameter: this._encode({
              id: item.id,
            }),
          },
        });
      },
      // 预约成功
      successChange() {
        this.$emit('successChange')
      },
      // 投递成功
      successSubmitResume() {
        this.$emit("successSubmitResume");
      }
    }
  }
</script>

<style scoped lang="less">
  .containerBox {
    padding: 0;
    position: relative;
    min-height: 200px;
  }

  .item {
    width: 100%;
    padding: 16px 30px;
    background: #ffffff;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 77px;
    .left {
      .job {
        cursor: pointer;
        font-weight: 800;
        color: #1890FF;
      }

      .des {
        margin-top: 4px;
        color: #666666;

        .money {
          font-weight: 400;
          color: #FF2B0D;
          margin-right: 16px;
        }

        .date {
          color: #999999;
          font-size: 12px;
          margin-left: 16px;
        }
      }
    }

    .show_type1 {
      display: flex;
      align-items: center;
      width: 54%;

      .content {
        width: 350px;
        display: flex;

        .img_box {
          width: 45px;
          height: 45px;
          margin-right: 6px;
          border-radius: 2px;
          border: 1px solid #E9E9E9;
          display: flex;
          justify-content: center;
          align-items: center;

          .img {
            max-width: 39px;
            height: 39px;
          }
        }

        .company {
          margin-top: 4px;
          color: #333333;
          width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }

        .company_des {
          font-size: 12px;
          color: #999999;
          margin-top: 4px;
        }
      }

      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }


      .btn {
        padding: 4px 22px;
        background: #1890FF;
        border-radius: 11px;
        border: 1px solid #1890FF;
        display: flex;
        color: #ffffff;
        font-size: 12px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .sendedBtn {
        padding: 4px 22px;
        background: #1890FF;
        border-radius: 11px;
        border: 1px solid #1890FF;
        display: flex;
        color: #ffffff;
        font-size: 12px;
        justify-content: center;
        align-items: center;
      }
      .sendBtn {
        background: #FFFFFF;
        color: #1890FF;
      }
      .btn:last-child {
        margin-top: 8px;
      }
    }
  }

  .margin_l_r8 {
    margin: 0 8px;
  }
  .margin_t_4 {
    margin-top: 4px;
  }
  .margin_r_12 {
    margin-right: 12px;
  }

</style>
